<template>
  <SwitchGroup>
    <div class="flex items-center">
      <Switch
        :modelValue="modelValue"
        @update:modelValue="(value) => emit('update:modelValue', value)"
        :class="[
          modelValue ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
          {
            'h-4 w-8': size === 'sm',
            'h-6 w-11': size === 'md',
          },
        ]"
      >
        <span class="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          :class="[
            'pointer-events-none inline-block transform rounded-full bg-white shadow transition duration-200 ease-in-out',
            {
              'translate-x-0': !modelValue,
              'h-3 w-3': size === 'sm',
              'h-5 w-5': size === 'md',
              'translate-x-4': size === 'sm' && modelValue,
              'translate-x-5': size === 'md' && modelValue,
            },
          ]"
        />
      </Switch>
      <SwitchLabel v-if="label" class="ml-3.5 text-sm">{{ label }}</SwitchLabel>
    </div>
  </SwitchGroup>
</template>

<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

withDefaults(
  defineProps<{
    modelValue: boolean;
    label?: string;
    size?: "sm" | "md";
  }>(),
  {
    size: "md",
  }
);

const emit = defineEmits(["update:modelValue"]);
</script>
